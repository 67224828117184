import Image from 'next/image'
import Link from 'next/link'

import { constants } from '../../../../lib/constants'
import { ROUTES } from '../../../../lib/routes'
import styles from './refer-a-friend.module.scss'

const ReferAFriend = ({ isVans }) => {
  return (
    <section className={styles.container}>
      <div className={styles.grid}>
        <div className={styles.column}>
          <div className={styles['loco-rewards']}>
            <h2 className={styles.heading}>Loco rewards</h2>
            <p className={styles.text}>
              Get a £20 Amazon voucher when you lease your {isVans ? 'van' : 'car'} through
              LeaseLoco.
            </p>
            <div className={styles['button-container']}>
              <Link href={ROUTES.rewardsPage.url}>
                <a className={styles['button-filled']}>
                  Claim your reward{' '}
                  <Image
                    src={`${constants.imageHost.assets}/icons/library/arrow-right.svg`}
                    alt=""
                    height="20"
                    width="20"
                  />
                </a>
              </Link>
              <Link href={ROUTES.rewardsTermsPage.url}>
                <a className={styles['button-text']}>
                  Learn more<span className="sr-only"> about Loco rewards</span>{' '}
                  <Image
                    src={`${constants.imageHost.assets}/icons/library/arrow-right.svg`}
                    alt=""
                    height="20"
                    width="20"
                  />
                </a>
              </Link>
            </div>
          </div>
        </div>
        <div className={styles.column}>
          <div className={styles['refer-a-friend']}>
            <h2 className={styles.heading}>Refer a friend</h2>
            <p className={styles.text}>
              Earn £20 Amazon vouchers for every friend you invite who goes on to lease through us.
            </p>
            <div className={styles['button-container']}>
              <Link href={ROUTES.referAFriendPage.url}>
                <a className={styles['button-filled']}>
                  Get a referral code{' '}
                  <Image
                    src={`${constants.imageHost.assets}/icons/library/arrow-right.svg`}
                    alt=""
                    height="20"
                    width="20"
                  />
                </a>
              </Link>
              <Link href={ROUTES.referAFriendTermsPage.url}>
                <a className={styles['button-text']}>
                  Learn more<span className="sr-only"> about refer a friend</span>{' '}
                  <Image
                    src={`${constants.imageHost.assets}/icons/library/arrow-right.svg`}
                    alt=""
                    height="20"
                    width="20"
                  />
                </a>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default ReferAFriend
